<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Change Schedule">
      <template #page-header>
        <h2 class="page-title">Change Schedule</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <RequestHeader
          :search="true"
          @addNew="openModalRequest"
          :lodaData="isChangeSchedulePersonalLoading"
          @onSearch="searchIconClick($event)"
        ></RequestHeader>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="changeScheduleRequest"
            :per-page="perPage"
            :loading="isChangeSchedulePersonalLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="date"
                label="Actual Date"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="change_date"
                label="Date Changed"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.changeDate) }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_reason"
                label="Reason"
                v-slot="props"
                sortable
              >
                <span>
                  {{ props.row.requestReason }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                field="created_at"
                label="Created At"
                v-slot="props"
                sortable
                width="15%"
              >
                <span>
                  {{ formatDate(props.row.createdAt) }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalRequest(changeScheduleRequest[props.index])
                  "
                ></b-icon>
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <b-sidebar
          v-if="isRightBarOpen && form != null"
          can-cancel
          type="is-light"
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Change Schedule Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar()"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>

            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Actual Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedColumn.date) }}
              </div>
              <div class="column is-4">Change To</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedColumn.changeDate) }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalized">
                {{ selectedColumn.status }}
              </div>
              <div class="column is-4">Reason</div>
              <div class="column is-8 has-text-weight-bold is-capitalized">
                {{ selectedColumn.requestReason }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedColumn.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="
                  selectedColumn.status === 'waiting' ||
                  selectedColumn.status === 'approved'
                "
              >
                <b-button
                  expanded
                  @click="cancelChangeSchedule(selectedColumn.id)"
                >
                  Cancel Request
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>

        <ChangeSchedulePersonalModal
          :show="isModalOpen"
          :formData="form"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          :listUser="listUser"
          :isFetchingUser="isFetchingUser"
          :notUpdatable="notUpdatable"
          :selectedUser="selectedUser"
          :minDate="minDate"
          :minChangeDate="minChangeDate"
          :locale="locale"
          @hide="closeModalRequest"
          @submit="submit"
          @getUserList="getUserList($event)"
          @selectUser="selectUser($event)"
          @getMoreUserList="getMoreUserList"
          @formatTimeSelect="formatTimeSelect($event)"
          @getDataActivityAttendanceByDate="
            getDataActivityAttendanceByDate($event)
          "
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { determineFontColorByStatus, showToast } from '@/services/util'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'
import isNotUpdatable from '../Script/isNotUpdatable'
import debounce from 'lodash/debounce'
import ApiService from '../../../services/common/api.service'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import ChangeSchedulePersonalModal from './ChangeSchedulePersonalModal.vue'

export default {
  components: {
    TimeOffApprovalHistoryComponent,
    RequestHeader,
    ChangeSchedulePersonalModal,
  },
  data() {
    const today = new Date()
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',

      isChangeSchedulePersonalLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      notUpdatable: null,
      selectedColumn: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      form: {
        id: null,
        actualDate: null,
        selectedDate: null,
        requestReason: null,
        status: null,
        additionalApproverId: null,
      },

      minDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      ), // First day of the month
      isSubmitting: null,
      locale: 'en-GB',

      minChangeDate: null,

      selectedUser: null,
      listUser: [],
      isFetchingUser: false,
      pageUserList: 1,
      lastPageUserList: 1,
      searchUser: null,
    }
  },
  computed: {
    ...mapGetters({
      changeScheduleRequest: 'changeSchedule/getChangeScheduleRequestData',
      currentUser: 'auth/currentUser',
      changeScheduleDetail: 'changeSchedule/getChangeScheduleDetail',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadChangeSchedulePersonal:
        'changeSchedule/fetchChangeScheduleRequestData',
      saveChangeSchedule: 'changeSchedule/saveChangeSchedule',
      updateChangeSchedule: 'changeSchedule/updateChangeScheduleRequest',
      actionLoadChangeScheduleDetail:
        'changeSchedule/fetchChangeScheduleDetail',
    }),

    ...mapMutations({
      setChangeSchedulePersonal: 'changeSchedule/setChangeScheduleRequestData',
      setChangeScheduleDetail: 'changeSchedule/setChangeScheduleDetail',
    }),

    /**
     * Open Request Modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalRequest(row) {
      if (row) {
        this.isEditing = true
        this.form = {
          id: row.id,
          actualDate: new Date(row.date),
          selectedDate: new Date(row.changeDate),
          requestReason: row.requestReason,
          status: row.status,
        }
        this.selectedUser = row.history ? row.history[0].fullName : '-'
        this.notUpdatable = isNotUpdatable.check(row.status, row.history)
      }
      this.isModalOpen = true
    },

    /**
     * Close request modal
     */
    closeModalRequest() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /** Submit change schedule request */
    async submit() {
      const form = new FormData()

      form.append('date', moment(this.form.actualDate).format('YYYY-MM-DD'))
      form.append(
        'change_date',
        moment(this.form.selectedDate).format('YYYY-MM-DD')
      )
      form.append('request_reason', this.form.requestReason)

      const today = moment(new Date())
      const actualDate = moment(this.form.actualDate)
      const newDate = moment(this.form.selectedDate)

      this.isSubmitting = true
      if (!this.isEditing) {
        form.append('additional_approver_id', this.form.additionalApproverId)

        if (!this.form.additionalApproverId) {
          form.delete('additional_approver_id')
        }
        try {
          await this.saveChangeSchedule(form)
          showToast('Data is successfully added!', 'is-success', 'is-top')
        } catch (e) {
          if (actualDate < today) {
            showToast('Date must be after today', 'is-danger', 'is-top')
          } else if (actualDate >= newDate) {
            showToast(
              'The new date must be after the original date',
              'is-danger',
              'is-top'
            )
          } else {
            showToast(e.response.data.message, 'is-danger', 'is-top')
          }
        }
      } else {
        try {
          const data = { id: this.form.id, form }

          await this.updateChangeSchedule(data)
          showToast('Data is successfully updated!', 'is-success', 'is-top')
        } catch (e) {
          if (actualDate < today) {
            showToast('Date must be after today', 'is-danger', 'is-top')
          } else if (actualDate > newDate) {
            showToast(
              'The new date must be after the original date',
              'is-danger',
              'is-top'
            )
          } else {
            showToast(e.response.message, 'is-danger', 'is-top')
          }
        }
      }

      this.isSubmitting = false
      this.closeModalRequest()
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {dateString} str - date string
     * @return {dateString} formatted date
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     * Load more data of change schedule personal list
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadChangeSchedulePersonalList()
        this.isLoadMore = false
      }
    },

    /**
     * Search table data
     * @param {String} searchInput - keyword search
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Reset state of table when form is created, updated
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setChangeSchedulePersonal([])
      await this.loadChangeSchedulePersonalList(searchInput)
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadChangeSchedulePersonalList()
    },

    /**
     * Reset form when close/ hide
     */
    resetForm() {
      this.form = {
        id: null,
        actualDate: null,
        selectedDate: null,
        requestReason: null,
        additionalApproverId: null,
      }
    },

    /**
     * Open Sidebar content from table
     * @param {Object} changeSchedule - selected overtime data from table row
     * @param {integer} id - selected data id
     */
    async openSidebar(changeSchedule, id) {
      if (id) {
        let response = await this.actionLoadChangeScheduleDetail(id)
        this.selectedColumn = response.data.data
      } else {
        this.selectedColumn = changeSchedule
      }
      this.isRightBarOpen = true
    },

    /**
     * Close Sidebar
     */
    closeSidebar() {
      this.isRightBarOpen = false
    },

    /**
     * Determine Profile picture from database
     * @param {Array} user - user info that is currently logged in
     * @return {String} user profile picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    /**
     * Select User from PIC database
     * @param {Object} option - selected user object (optional)
     */
    selectUser(option) {
      if (option) {
        this.selectedUser = option.fullName
        this.form.additionalApproverId = option.id
      }
    },

    /**
     * Get User list from database
     * @return {Object} listUser - User List
     */
    getUserList: debounce(function (name) {
      this.form.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      this.isFetchingUser = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),

    /**
     * Get more user list when auto complete scrolled
     * @return {Object} listUser - User List
     */
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),

    /**
     * Cancel Change Schedule Request
     * @param {integer} id - Request Id
     */
    async cancelChangeSchedule(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        const form = new FormData()

        form.append('status', 'canceled')
        this.isSubmitting = true
        try {
          form.append('_METHOD', 'PUT')
          const data = { id, form }
          await this.updateChangeSchedule(data)
          showToast('Request canceled', 'is-success', 'is-top')
          this.isRightBarOpen = false
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-top')
        }
      }
    },

    /**
     * Load Change Schedule Personal List data.
     * @param {String} searchInput - keyword search (optional)
     */
    async loadChangeSchedulePersonalList(searchInput) {
      this.isChangeSchedulePersonalLoading = true
      try {
        const response = await this.actionLoadChangeSchedulePersonal({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isChangeSchedulePersonalLoading = false
    },
  },
  watch: {
    'form.actualDate': function (val) {
      if (val) {
        // Add a day
        this.minChangeDate = new Date(val.toDateString())
        this.minChangeDate.setDate(this.minChangeDate.getDate() + 1)
      }
    },
  },
  async mounted() {
    this.setChangeSchedulePersonal([])
    await this.loadChangeSchedulePersonalList()
    this.minChangeDate = this.minDate
    if (this.$route.query.id) {
      await this.openSidebar(null, this.$route.query.id)
    }
  },
}
</script>
